
























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SideBar extends Vue {
  items = []
  initialized = false

  mounted() {
    this.items = [
      { icon: 'fa-cogs', url: 'Settings/Economy/SalesAccountingRules/List', title: 'Sales accounting' },
      { icon: 'fa-cogs', url: 'Settings/Economy/PaymentAccountingRules/List', title: 'Payment accounting' },
      { icon: 'fa-cogs', url: 'Settings/Economy/CostAccountingRules/List', title: 'Cost accounting' },
      { icon: 'fa-cogs', url: 'Settings/Economy/Accounts/List', title: 'Account list' },
      { icon: 'fa-cogs', url: 'Settings/Economy/VatCodes/List', title: 'VAT' },
      { icon: 'fa-cogs', url: 'Settings/Economy/CostCenters/List', title: 'Cost Centers' },
    ]
    this.initialized = true
  }

  getUrl(part) {
    if (part[0] === '/') {
      return part
    } else {
      return { name: part }
    }
  }
}
